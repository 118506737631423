import React from 'react';

import { Layout } from '../../components/shared/Layout';
import { Seo } from '../../components/shared/Seo';
import { Button } from '../../components/shared/Button';
import { useGTMEvent } from '../../hooks/useGTMEvent';

import speedmeter from '../../images/index/speedmeter.png';
import percentage from '../../images/index/percentage.png';
import tag from '../../images/index/tag.png';
import lock from '../../images/index/lock.png';
import stopwatch from '../../images/index/stopwatch.png';

import imageSrc from '../../images/index/bg-couple.jpg';

export const Index = () => {
  const pushHeaderGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:header',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_header',
  });

  const pushBenefitsGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:benefits',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_benefits',
  });

  const pushFooterGTMEvent = useGTMEvent({
    event: 'homepage:findoutscore:footer',
    eventCategory: 'homepage',
    eventAction: 'button',
    eventLabel: 'homepage_findoutscore_footer',
  });

  return (
    <Layout
      logoVariant="light"
      loginVariant="light"
      footerVariant="light"
      showLoginButton
      noVerticalPadding
    >
      <Seo title="Bonita klienta: zjistěte, jak vás vidí banky" />

      <section
        className="pt-48 pb-32 px-4 text-white bg-cover bg-center"
        style={{
          backgroundImage: `linear-gradient(44.94deg, rgba(23, 30, 83, 0.7) 2.7%, rgba(0, 15, 134, 0.7) 53.86%, rgba(127, 49, 204, 0.7) 114.13%), url(${imageSrc})`,
        }}
      >
        <div className="md:grid grid-cols-2 gap-x-4 mx-auto max-w-screen-xl">
          <div className="space-y-8">
            <h1
              className="text-5xl font-bold leading-[64px]"
              data-testid="index-title"
            >
              Osobní skóre napoví, jaký úrok můžete dostat
            </h1>
            <h2
              className="sm:max-w-[642px] text-[22px] leading-[33px]"
              data-testid="index-subtitle"
            >
              Když si půjdete půjčit, poznáte, jestli je pro vás nabídka
              výhodná. U&nbsp;současných půjček můžete posoudit, jestli máte
              dobrý úrok, nebo zda zvážit refinancování.
            </h2>
            <div>
              <Button
                to="/ucet/registrace"
                color="primary"
                border="normal"
                onClick={pushHeaderGTMEvent}
                data-testid="index-button-want-find-my-score-first"
              >
                chci zjistit své skóre
              </Button>
            </div>
          </div>
          <div className="hidden md:flex items-center justify-end">
            <img src={speedmeter} alt="Speedmeter" aria-hidden="true" />
          </div>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2
            className="text-4xl tracking-[0.0125em] font-bold leading-10"
            data-testid="index-second-section-title"
          >
            Osobní skóre se hodí, když...
          </h2>
          <div className="flex flex-col md:grid md:grid-cols-4 md:grid-rows-1 md:gap-x-12 gap-y-8 my-10">
            <div>
              <img
                className="mx-auto max-w-xs"
                src={percentage}
                alt="Symbol procenta"
                aria-hidden="true"
              />
              <p
                className="mb-2 md:mb-4 text-[22px] font-medium"
                data-testid="index-second-section-reason-one-title"
              >
                Potřebujete zjistit, jaký úrok byste mohli reálně dostat
              </p>
              <p
                className="text-xl font-light tracking-tight"
                data-testid="index-second-section-reason-one-subtitle"
              >
                Na kalkulačkách obvykle vidíte jen nejnižší sazbu.
              </p>
            </div>
            <div>
              <img
                className="mx-auto max-w-xs"
                src={tag}
                alt="Symbol štítku"
                aria-hidden="true"
              />
              <p
                className="mb-2 md:mb-4 text-[22px] font-medium"
                data-testid="index-second-section-reason-two-title"
              >
                Nechcete rovnou žádat o&nbsp;půjčku
              </p>
              <p
                className="px-10 md:px-0 text-xl font-light tracking-tight"
                data-testid="index-second-section-reason-two-subtitle"
              >
                Opakované zamítnutí žádosti vám může zhoršit nabídku úroku.
              </p>
            </div>
            <div>
              <img
                className="mx-auto max-w-xs"
                src={lock}
                alt="Symbol zámku"
                aria-hidden="true"
              />
              <p
                className="mb-2 md:mb-4 text-[22px] font-medium"
                data-testid="index-second-section-reason-three-title"
              >
                Hledáte službu, která chrání vaše osobní data
              </p>
              <p
                className="px-10 md:px-0 text-xl font-light tracking-tight"
                data-testid="index-second-section-reason-three-subtitle"
              >
                Informace, které nám dáte, nikam dál nesdílíme.
              </p>
            </div>
            <div>
              <img
                className="mx-auto max-w-xs"
                src={stopwatch}
                alt="Symbol časovače"
                aria-hidden="true"
              />
              <p
                className="mb-2 md:mb-4 text-[22px] font-medium"
                data-testid="index-second-section-reason-four-title"
              >
                Chcete získat informace zdarma, rychle a online
              </p>
              <p
                className="px-10 md:px-0 text-xl font-light tracking-tight"
                data-testid="index-second-section-reason-four-subtitle"
              >
                Do pár minut znáte svůj výsledek, stačí jen pár kliknutí.
              </p>
            </div>
          </div>
          <Button
            to="/ucet/registrace"
            color="secondary"
            border="normal"
            onClick={pushBenefitsGTMEvent}
            data-testid="index-button-want-find-my-score-second"
          >
            chci zjistit své skóre
          </Button>
        </div>
      </section>

      <section className="py-20 px-4 bg-tips-pattern bg-cover bg-secondary text-white">
        <div className="max-w-screen-xl mx-auto text-center">
          <h2
            className="text-4xl tracking-[0.0125em] font-bold leading-10"
            data-testid="index-third-section-title"
          >
            Jak to bude probíhat?
          </h2>

          <div className="relative grid md:grid-cols-3 gap-y-7 md:gap-x-12 my-10">
            <div className="hidden absolute md:block w-full top-[14px]">
              <div className="w-4/6 h-full mx-auto my-4 border-white/50 border-t-2 border-dashed z-10"></div>
            </div>

            <div className="flex justify-center order-1 md:order-1 z-20">
              <div className="flex justify-center items-center h-[61px] w-[61px] text-secondary text-2xl font-medium bg-circle bg-cover">
                1
              </div>
            </div>
            <div className="flex justify-center order-4 md:order-2 z-20">
              <div className="flex justify-center items-center h-[61px] w-[61px] text-secondary text-2xl font-medium bg-circle bg-cover">
                2
              </div>
            </div>
            <div className="flex justify-center order-7 md:order-3 z-20">
              <div className="flex justify-center items-center h-[61px] w-[61px] text-secondary text-2xl font-medium bg-circle bg-cover">
                3
              </div>
            </div>

            <p
              className="text-[22px] font-bold order-2 md:order-4"
              data-testid="index-third-section-step-one-title"
            >
              Vyplníte pár věcí o sobě
            </p>
            <p
              className="text-[22px] font-bold order-5 md:order-5"
              data-testid="index-third-section-step-two-title"
            >
              Nahrajete bankovní výpisy
            </p>
            <p
              className="text-[22px] font-bold order-8 md:order-6"
              data-testid="index-third-section-step-three-title"
            >
              A je to!
            </p>

            <p
              className="mb-1 md:mb-1 text-xl font-light order-3 md:order-7"
              data-testid="index-third-section-step-one-subtitle"
            >
              Snadno, online a&nbsp;za pár minut.
            </p>
            <p
              className="mb-1 md:mb-1 text-xl font-light order-6 md:order-8"
              data-testid="index-third-section-step-two-subtitle"
            >
              Z účtu, na který vám chodí příjem, za&nbsp;poslední 3&nbsp;měsíce
              ve formátu PDF.
            </p>
            <p
              className="mb-1 md:mb-0 text-xl font-light order-9 md:order-9"
              data-testid="index-third-section-step-three-subtitle"
            >
              Ukážeme vám vaše skóre a&nbsp;pravděpodobný&nbsp;úrok.
            </p>
          </div>

          <Button
            to="/ucet/registrace"
            color="primary"
            border="normal"
            onClick={pushBenefitsGTMEvent}
            data-testid="index-button-want-find-my-score-third"
          >
            chci zjistit své skóre
          </Button>
        </div>
      </section>

      <section className="py-16 px-4">
        <div className="max-w-screen-xl mx-auto flex flex-col md:flex-row text-secondary">
          <div className="w-full md:w-1/2 text-center md:text-left">
            <h2
              className="mb-10 md:mb-0 text-4xl tracking-wide font-bold leading-10 md:leading-loose"
              data-testid="index-fourth-section-title"
            >
              Co je osobní skóre?
            </h2>
          </div>
          <div className="w-full md:w-1/2 flex flex-col gap-y-12 text-center md:text-left">
            <div>
              <h3
                className="mb-4 text-[22px] font-bold"
                data-testid="index-fourth-section-step-one-title"
              >
                Co je osobní skóre?
              </h3>
              <p
                className="leading-8"
                data-testid="index-fourth-section-step-one-paragraph"
              >
                Osobní skóre funguje na principu výpočtu kreditního skóre
                a&nbsp;slouží k&nbsp;vyhodnocení toho,{' '}
                <span className="font-bold">
                  za&nbsp;jaký úrok si pravděpodobně budete moci půjčit
                </span>
                .
              </p>
            </div>

            <div>
              <h3
                className="mb-4 text-[22px] font-bold"
                data-testid="index-fourth-section-step-two-title"
              >
                Co je kreditní skóre?
              </h3>
              <p
                className="leading-8"
                data-testid="index-fourth-section-step-two-paragraph"
              >
                Je to číslo, které vyjadřuje, jak spolehlivě člověk splácí své
                půjčky. Banky podle něj posuzují, zda mu půjčí peníze
                a&nbsp;jaké je s&nbsp;tím spojené riziko. Čím vyšší skóre člověk
                má, tím je pro banku spolehlivější a&nbsp;může získat lepší
                úrok.
              </p>
            </div>

            <div>
              <h3
                className="mb-4 text-[22px] font-bold"
                data-testid="index-fourth-section-step-three-title"
              >
                Jak osobní skóre počítáme?
              </h3>
              <p
                className="leading-8"
                data-testid="index-fourth-section-step-three-paragraph"
              >
                Osobní skóre počítáme na základě příjmů a&nbsp;výdajů
                z&nbsp;bankovních výpisů, které nám poskytnete. Nezkoumáme
                záznamy v&nbsp;registrech dlužníků nebo insolvenčním rejstříku,{' '}
                <span className="font-bold">
                  proto vaše žádost nezanechá v&nbsp;těchto registrech žádný
                  záznam
                </span>
                . Na druhou stranu, při reálné žádosti o&nbsp;půjčku banky
                s&nbsp;registry pracují, což může váš konečný úrok ještě
                ovlivnit.
              </p>
            </div>

            <div>
              <h3
                className="mb-4 text-[22px] font-bold"
                data-testid="index-fourth-section-step-four-title"
              >
                Co je bonita?
              </h3>
              <p
                className="leading-8"
                data-testid="index-fourth-section-step-four-paragraph"
              >
                Bonita vyjadřuje vaši celkovou finanční situaci. Zahrnuje
                aktuální příjmy, výdaje, historii splácení, stávající půjčky
                a&nbsp;další faktory.
              </p>
            </div>

            <div>
              <Button
                to="/ucet/registrace"
                color="secondary"
                border="normal"
                onClick={pushFooterGTMEvent}
                data-testid="index-button-want-find-my-score-fourth"
              >
                chci zjistit své skóre
              </Button>
            </div>
          </div>
        </div>
      </section>

      <section className="h-52 sm:h-32 md:h-28 bg-tips-pattern bg-cover bg-secondary">
        {/* footer is rendered in here */}
      </section>
    </Layout>
  );
};
